<style src="./appeal-radio-service.component.scss" lang="scss"></style>

<template>
  <div class="appeal-radio-service mb-100 mt-16">
    <div class="container-fluid v-container">
      <div class="row">
        <div class="col-12">
          <section class="mt-20 mb-16">
            <service-name-component
                :serviceName="commonService.name"
                :organization="commonService.structureName"
                :img="commonService.file"
                :payment="commonService.paymentRequired"
                :registered="commonService.authRequired"
                :serviceId="commonService.id"
                :structureId="commonService.structureId"
                :headers="commonService.serviceHeader"
            />
          </section>
        </div>
        <div class="col-md-12">
          <customer-info-component v-if="userData && current>=3" :user="userData"/>
          <customer-info-component v-if="user?.name?.length && current>=2" :user="user"/>
        </div>
        <div class="col-12">
          <v-card>
            <step-appeal-radio-component
                @setPersonalInformation="setPersonalInformation"
                :organizationName="organizationName"
                @checkTin="checkTin"
                :frequencyUnits="frequencyUnits"
                :userLogin="!!user?.pin?.length"
                :interferenceTechnologyTypes="interferenceTechnologyTypes"
                :interferenceTypes="interferenceTypes"
                :durations="durations"
                :documentSeriesType="documentSeriesType"
                @addDates="addDates"
                @deleteDate="deleteDate"
                :radioInformation="radioInformation"
                :errorRadioInformation="errorRadioInformation"
                :personalInformation="personalInformation"
                :errorPersonalInformation="errorPersonalInformation"
                :stepOneValidation="stepOneValidation"
                :organizationLegalFormValue="organizationLegalFormValue"
                :citizenshipValue="citizenshipValue"
                :legalPersonCarrier="legalPersonCarrier"
                :organizationLegalForm="organizationLegalForm"
                :citizenship="citizenship"
                :step="step"
                :stepDescription="stepDescription"
                :current="current"
            />
            <v-divider class="my-40"/>
            <div class="btn__wrapper d-flex justify-center mt-40">

              <v-btn v-if="current!==1" @click="decrementCurrent" color="white mr-12">{{ locale?.prevStep }}</v-btn>
              <v-btn @click="incrementCurrent" v-if="!(step===current)" color="dark">{{ locale?.nextStep }}</v-btn>
              <v-btn v-if="current === step" color="dark" @click="finallyStep">Göndər</v-btn>
            </div>
          </v-card>
        </div>
      </div>
    </div>
    <v-modal
        @closeModal="closeModal"
        title="Məlumat"
        ref="modal"
    >
      <template v-slot:body>
        <img class="service__img"
             src="@/assets/images/icons/modal-info.svg" alt="">
        <div class="mb-20"> Müraciətiniz qeydə alındı. Təşəkkür edirik!</div>

      </template>
      <template v-slot:footer>
        <div class="modal-btn-group text-center">
          <v-btn @click="closeModal">{{ locale?.close }}
          </v-btn>
        </div>
      </template>
    </v-modal>
  </div>
</template>

<script src="./appeal-radio-service.component.ts"></script>