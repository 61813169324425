import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import StepDescriptionComponent from '@/core/shared/step-description/step-description.component.vue';
import ProgressbarComponent from '@/core/shared/progressbar/progressbar.component.vue';
import GeneralInformationFormComponent
    from '@/pages/appeal-radio-service/components/general-information-form/general-information-form.component.vue';
import PersonalInformationFormComponent
    from '@/pages/appeal-radio-service/components/personal-information-form/personal-information-form.component.vue';
import RadioInformationFormComponent
    from '@/pages/appeal-radio-service/components/radio-Information-form/radio-Information-form.component.vue';

@Options({
    name: 'step-appeal-radio-component',
    components: {
        StepDescriptionComponent,
        ProgressbarComponent,
        GeneralInformationFormComponent,
        PersonalInformationFormComponent,
        RadioInformationFormComponent,
    },
    props: {
        userLogin: {
            type: Boolean,
            default: false
        },
        userData: {
            type: Object, require: true
        },
        documentSeriesType: {
            type: Array, require: true
        },
        legalPersonCarrier: {
            type: [Boolean, null], require: true
        },
        organizationLegalForm: {
            type: Array, require: true
        },
        citizenship: {
            type: Array, require: true
        },
        stepDescription: {
            type: Array,
            require: true
        },
        current: {
            type: Number, require: true
        },
        step: {
            type: Number, require: true
        },
        citizenshipValue: {
            type: Number, require: true
        },
        organizationLegalFormValue: {
            type: Number, require: true
        },
        stepOneValidation: {
            type: Object,
            require: true
        },
        personalInformation: {
            type: Object,
            require: true
        },
        errorPersonalInformation: {
            type: Object,
            require: true
        },
        errorRadioInformation: {
            type: Object,
            require: true
        },
        radioInformation: {
            type: Object,
            require: true
        },

        interferenceTechnologyTypes: {
            type: Array,
            require: true
        },
        durations: {
            type: Array,
            require: true
        },
        interferenceTypes: {
            type: Array,
            require: true
        },
        frequencyUnits: {
            type: Array,
            require: true
        },
        organizationName: {
            type: String, require: false
        },
    },
    emits: ['setPersonalInformation', 'deleteDate', 'addDates', 'checkTin']
})

export default class StepAppealRadioComponent extends Vue {

    public deleteDate(info: any) {
        this.$emit('deleteDate', info);
    }

    public addDates() {
        this.$emit('addDates');
    }
    public checkTin() {
        this.$emit('checkTin');
    }
}