import 'reflect-metadata';
import {mixins, Options, Vue} from 'vue-class-component';
import {store} from '@/store';
import {locale} from '@/assets/db/db.conf.ts';
import StepsCardComponent from '@/pages/services/components/steps-card/steps-card.component.vue';
import CustomerInfoComponent from '@/core/shared/customer-info/customer-info.component.vue';
import ServiceNameComponent from '@/core/shared/service-name/service-name.component.vue';
import StepPermissionComponent
    from '@/pages/permission-movement-service/components/step-permission/step-permission.component.vue';
import {IServiceCommon} from '@/types/service-common';
import {IServiceHeader} from '@/types/service-header';
import {container} from 'tsyringe';
import {CommonSandbox} from '@/sandbox/common.sandbox';
import StepAppealRadioComponent
    from '@/pages/appeal-radio-service/components/step-appeal-radio/step-appeal-radio.component.vue';
import {Validation} from '@/core/mixin/validation';
import {AppealRadioServiceSandbox} from '@/pages/appeal-radio-service/sandbox/appeal-radio-service.sandbox';
import {PostApplicantModel} from '@/pages/appeal-radio-service/models/post-applicant.model';
import {PostAppealModel} from '@/pages/appeal-radio-service/models/post-appeal.model';
import {CreateFormData} from '@/core/mixin/mixin';
import {IUserData} from '@/pages/appeal-radio-service/types/user-data';
import {load} from 'recaptcha-v3';
import {Router} from '@/core/mixin/router';
import {IInputPayload} from '@/types/input-payload';


@Options({
    name: 'appeal-radio-service-component',
    components: {
        StepsCardComponent,
        CustomerInfoComponent,
        ServiceNameComponent,
        StepPermissionComponent,
        StepAppealRadioComponent,
    },
    watch: {
        citizenshipValue: {
            handler: 'changeCitizenshipValue',
            immediate: false,
            deep: false,
        },
        personalInformation: {
            handler: 'changePersonalInformation',
            immediate: false,
            deep: false,
        }
    }
})

export default class AppealRadioServiceComponent extends mixins(Validation, CreateFormData, Router) {
    public siteKey = '6LdZg8IfAAAAAEzXF_7RQY0AzNjQ4zhPPR-5Tf5P';
    public sandboxCommon: CommonSandbox = container.resolve(CommonSandbox);
    public sandbox: AppealRadioServiceSandbox = container.resolve(AppealRadioServiceSandbox);
    public store = store;
    public step: number = 3;
    public current: number = 1;
    public locale$: any = locale;
    public locale: any = null;
    public localeService: any = null;
    public requestCitizenship = false;
    public organizationNameValue = '';
    public oldTin = '';

    public $refs!: {
        modal: {
            openModal: () => void;
            closeModal: () => void
        },
    };
    public stepOneValidation = {
        citizenship: false,
        organizationLegalForm: false
    };

    public errorPersonalInformation = {
        documentNumber: false,
        documentTypeNumber: false,
        fin: false,
        phone: false,
        email: false,
        tin: false,
        organization: false,
        position: false,
        registration: false,
    };
    public errorRadioInformation = {
        duration: false,
        dates: [{
            startDate: false,
            startTime: false,
            endDate: false,
            endTime: false
        }],
        frequencyBand: false,
        typeTechnology: false,
        obstacleDirection: false,
        natureObstacle: false,
        obstacleArea: false,
        technicalIndicators: false,
        frequencyUnits: false,
        file: false,
    };

    get user() {
        if (this.store.getters._getUserFullData?.pin?.length) {
            if (this.store.getters._getUserFullData?.pin?.length === 7) {
                this.store.commit('SET_CITIZENSHIP_VALUE', 1);
            } else {
                this.store.commit('SET_CITIZENSHIP_VALUE', 2);
            }
        }
        return this.store.getters._getUserFullData;
    }

    get userData(): IUserData {
        return this.store.getters._getUserDataRadio;
    }

    get durations() {
        return this.store.getters._getDurations;
    }

    get interferenceTypes() {
        return this.store.getters._getInterferenceTypes;
    }

    get interferenceTechnologyTypes() {
        return this.store.getters._getInterferenceTechnologyTypes;
    }

    get commonService(): IServiceCommon {
        return this.store.getters._getCommonService;
    }

    get serviceHeader(): IServiceHeader {
        return this.store.getters._getCommonServiceHeader;
    }

    get citizenship() {
        return this.store.getters._getCitizenship;
    }

    get organizationLegalForm() {
        return this.store.getters._getOrganPersonType;
    }

    get legalPersonCarrier() {
        return this.store.getters._getLegalPersonCarrier;
    }

    get citizenshipValue() {
        return this.store.getters._getCitizenshipValue;
    }

    get organizationLegalFormValue() {
        return this.store.getters._getOrganizationLegalFormValue;
    }

    get personalInformation() {
        return this.store.getters._getAppealPersonalInformation;
    }

    get documentSeriesType() {
        return this.store.getters._getDocumentSeriesType;
    }

    get applicant() {
        return this.store.getters._getApplicantId;
    }

    get organizationName() {
        return this.store.getters._getRadioOrganizationName;
    }

    get stepDescription() {
        return [
            {
                number: 1,
                description: this.localeService?.step1,
            },
            {
                number: 2,
                description: this.localeService?.step2,
            },
            {
                number: 3,
                description: this.localeService?.step3,
            },
        ];
    }

    get radioInformation() {
        return this.store.getters._getRadioInformation;
    }

    get frequencyUnits() {
        return this.store.getters._getFrequencyUnits;
    }

    public mounted(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.elements) {
                this.locale = res.elements;
                this.localeService = res.permissionMovementService;
            }
        });
        this.sandbox.getRadioCitizenship();
        this.sandbox.getRadioOrganPersonType();
        this.sandbox.getEffectDurations();
        this.sandbox.getInterferenceTechnologyTypes();
        this.sandbox.getInterferenceTypes();
        this.sandbox.getRadioFrequencyUnits();
        this.getServiceData();
    }
    public unmounted() {
        this.store.commit('SET_RESET_ORGANIZATION_LEGAL_FORM_VALUE');
        this.store.commit('SET_RESET_CITIZENSHIP_VALUE');
        this.store.commit('SET_RESET_LEGAL_PERSONAL');
    }

    public setPersonalInformation(info: IInputPayload) {
        this.store.commit('SET_RADIO_PERSONAL_ORGANIZATION', info);
    }

    public changeCitizenshipValue(val: any, oldVal: any) {
        if (val !== oldVal) {
            this.requestCitizenship = true;
        }
    }

    public changeUser(val: any, oldVal: any) {
        if (val?.pin?.length) {
            if (val?.pin?.length === 7) {
                this.store.commit('SET_CITIZENSHIP_VALUE', 1);
            } else {
                this.store.commit('SET_CITIZENSHIP_VALUE', 2);
            }
        }
    }

    public deleteDate(info: any) {
        this.errorRadioInformation.dates.splice(info, 1);
    }

    public addDates() {
        this.errorRadioInformation.dates.push({
            startDate: false,
            startTime: false,
            endDate: false,
            endTime: false
        });
    }

    // public deleteObstacleArea(info: any) {
    //     this.errorRadioInformation.obstacleArea.splice(info, 1);
    // }
    //
    // public addObstacleArea() {
    //     this.errorRadioInformation.obstacleArea.push(false);
    // }

    public decrementCurrent(): void {
        switch (this.current) {
            case 1:
                break;
            case 2:
                this.current = --this.current;
                break;
            case 3:
                this.current = --this.current;
                break;
        }
    }

    public incrementCurrent() {
        switch (this.current) {
            case 1:
                this.checkValidationFirstStep();
                break;
            case 2:
                this.checkValidationSecondStep();
                break;
            case 3:
                this.checkValidationThirdStep();
                break;
        }
    }

    public finallyStep() {
        this.checkValidationThirdStep();
    }

    public changePersonalInformation(val: any, old: any) {
        // console.log(val.voen);
    }

    public checkValidationFirstStep() {
        this.checkGeneralInfoValidation();
        if (!this.checkValidationError(this.stepOneValidation)) {
            if (!this.user?.pin?.length && this.requestCitizenship) {
                this.sandbox.getRadioDocumentSeriesType(this.citizenshipValue);
            }
            this.requestCitizenship = false;
            this.current = 2;
        }
    }

    public checkValidationSecondStep() {
        this.checkPersonalInformationInfoValidation();
        if (!this.checkValidationError(this.errorPersonalInformation)) {
            this.sandbox.postApplicant(new PostApplicantModel(this.personalInformation, this.organizationLegalFormValue, this.legalPersonCarrier, this.citizenshipValue)).then((res: any) => {
                this.store.commit('SET_APPLICANT', res);
                this.current = 3;
            });
        }

    }


    public checkValidationThirdStep() {
        this.checkRadioInfoValidation();
        if (!this.checkValidationError(this.errorRadioInformation)
            && !this.checkValidationDates().endDate
            && !this.checkValidationDates().endTime
            && !this.checkValidationDates().startDate
            && !this.checkValidationDates().startTime) {

            let data = new PostAppealModel(this.radioInformation, this.applicant);
            this.sandbox.postAppeal(this.createFormData(data)).then((res: any) => {
                if (res.status === 201) {
                    this.openModal();
                }
            });
        }
    }

    public openModal() {
        this.$refs.modal.openModal();
    }

    public closeModal() {
        this.store.commit('SET_DATA_RESET');
        this.store.commit('SET_RESET_ORGANIZATION_LEGAL_FORM_VALUE');
        this.store.commit('SET_RESET_CITIZENSHIP_VALUE');
        this.$refs.modal.closeModal();
        this.current = 1;
        this.$router.push({name: 'home'});
    }

    public beforeUnmount() {
        this.store.commit('SET_DATA_RESET');
        this.store.commit('SET_RESET_ORGANIZATION_LEGAL_FORM_VALUE');
        this.store.commit('SET_RESET_CITIZENSHIP_VALUE');
    }

    public checkRadioInfoValidation() {
        this.checkValidationData(this.errorRadioInformation
            , this.radioInformation.duration
            , 'duration'
        );

        this.checkValidationData(this.errorRadioInformation
            , this.radioInformation.frequencyBand
            , 'frequencyBand'
        );

        this.checkValidationData(this.errorRadioInformation
            , this.radioInformation.typeTechnology
            , 'typeTechnology'
        );

        this.checkValidationData(this.errorRadioInformation
            , this.radioInformation.obstacleDirection
            , 'obstacleDirection'
        );


        this.checkValidationData(this.errorRadioInformation
            , this.radioInformation.frequencyUnits
            , 'frequencyUnits'
        );
        this.checkValidationData(this.errorRadioInformation
            , this.radioInformation.obstacleArea
            , 'obstacleArea'
        );

        this.validationDates();
        // this.validationObstacleArea();
    }

    public validationDates() {
        this.radioInformation.dates.forEach((item: any, index: any) => {
            if (item.startDate) {

                this.errorRadioInformation.dates[index].startDate = false;
            } else {
                this.errorRadioInformation.dates[index].startDate = true;
            }
            if (item.startTime) {
                this.errorRadioInformation.dates[index].startTime = false;
            } else {
                this.errorRadioInformation.dates[index].startTime = true;
            }
            if (item.endDate) {
                this.errorRadioInformation.dates[index].endDate = false;
            } else {
                this.errorRadioInformation.dates[index].endDate = true;
            }
            if (item.endTime) {
                this.errorRadioInformation.dates[index].endTime = false;
            } else {
                this.errorRadioInformation.dates[index].endTime = true;
            }
        });
    }

    public checkTin() {
        load((this.siteKey), {}).then((re) => {
            re.execute('sendFeedback').then((token) => {
                this.sandbox.getRadioOrganizationName({
                    SecretKey: token,
                    applicantTypeId: this.organizationLegalFormValue,
                    tin: this.personalInformation.tin,

                });
            });
        });
    }

    public checkValidationDates() {
        let common = {
            startDate: false,
            startTime: false,
            endDate: false,
            endTime: false,
        };
        this.errorRadioInformation.dates.forEach((item: any) => {
            if (item.startDate) {
                common.startDate = true;
            }
            if (item.startTime) {
                common.startTime = true;
            }
            if (item.endDate) {
                common.endDate = true;
            }
            if (item.endTime) {
                common.endTime = true;
            }
        });
        return common;
    }


    public checkGeneralInfoValidation() {
        this.citizenshipValue ?
            this.stepOneValidation.citizenship = false : this.stepOneValidation.citizenship = true;
        this.organizationLegalFormValue ?
            this.stepOneValidation.organizationLegalForm = false : this.stepOneValidation.organizationLegalForm = true;
    }


    public checkPersonalInformationInfoValidation() {
        if (!(this.user?.pin?.length > 0)) {
            this.checkValidationData(this.errorPersonalInformation
                , this.validationDocumentNumber(this.personalInformation.documentTypeNumber?.value, this.personalInformation.documentNumber)
                , 'documentNumber');
            this.checkValidationData(this.errorPersonalInformation,
                this.personalInformation.documentTypeNumber?.id, 'documentTypeNumber');
            this.checkValidationData(this.errorPersonalInformation
                , this.validationFin(this.personalInformation.documentTypeNumber?.value, this.personalInformation.fin)
                , 'fin');
            this.checkValidationData(this.errorPersonalInformation
                , this.validationPhone(this.personalInformation.phone)
                , 'phone'
            );
        }
        this.checkValidationData(this.errorPersonalInformation
            , this.validationEmail(this.personalInformation.email)
            , 'email'
        );
        if (this.organizationLegalFormValue === 2) {
            this.checkValidationData(this.errorPersonalInformation
                , this.validationVoen(this.personalInformation.tin)
                , 'tin'
            );
            this.checkValidationData(this.errorPersonalInformation
                , this.personalInformation.organization
                , 'organization'
            );
            this.checkValidationData(this.errorPersonalInformation
                , this.personalInformation.position
                , 'position'
            );
        }
    }
}