export class PostAppealModel {
    public frequencyBand: number | string | null = null;
    public interferenceDirection: string | null = null;
    public interferenceEffectTechnicalParameter: string | null = null;
    public effectDurationId: string | null = null;
    public applicantId: number | null = null;
    public appealInterferenceTechnologyTypeId: number | null = null;
    public appealInterferenceTypeIds: [] | null = null;
    public effectDurationDateRanges: [] | null = null;
    public interferenceEffectArea: string | null = null;
    public files: number | null = null;
    public frequencyUnitId: number | null = null;

    constructor(data: any, applicantId: any) {
        this._setFrequencyBand(data);
        this._setInterferenceDirection(data);
        this._setInterferenceEffectTechnicalParameter(data);
        this._setEffectDurationId(data);
        this._setApplicantId(applicantId);
        this._setAppealInterferenceTechnologyTypeId(data);
        this._setAppealInterferenceTypeIds(data);
        this._setEffectDurationDateRanges(data);
        this._setInterferenceEffectAreaName(data);
        this._setFiles(data);
        this._setFrequencyUnitId(data);
    }


    private _setFrequencyBand({frequencyBand}: any) {
        this.frequencyBand = frequencyBand;
    }

    private _setInterferenceDirection({obstacleDirection}: any) {
        this.interferenceDirection = obstacleDirection;
    }

    private _setInterferenceEffectTechnicalParameter({technicalIndicators}: any) {
        this.interferenceEffectTechnicalParameter = technicalIndicators;
    }

    private _setEffectDurationId({duration}: any) {
        this.effectDurationId = duration;
    }

    private _setApplicantId(applicantId: any) {
        this.applicantId = applicantId;
    }

    private _setAppealInterferenceTechnologyTypeId({typeTechnology}: any) {
        this.appealInterferenceTechnologyTypeId = typeTechnology;
    }

    private _setAppealInterferenceTypeIds({natureObstacle}: any) {
        this.appealInterferenceTypeIds = natureObstacle;
    }

    private _setEffectDurationDateRanges({dates, duration}: any) {
        if (duration !== 2) {
            this.effectDurationDateRanges = dates.map((item: any) => {
                let timezoneHour = -(Math.trunc(Number(item.startDate.getTimezoneOffset()) / 60)),
                    timezoneMinutes = -(Number(item.startDate.getTimezoneOffset()) % 60),
                    hourStart = Number(item.startTime?.slice(0, 2)) + timezoneHour,
                    hoursEnd = Number(item.endTime?.slice(0, 2)) + timezoneHour,
                    minuteStart = Number(item.startTime?.slice(-2)) + timezoneMinutes,
                    minuteEnd = Number(item.endTime?.slice(-2)) + timezoneMinutes;

                item.startDate.setHours(hourStart, minuteStart, 0, 0);
                item.endDate.setHours(hoursEnd, minuteEnd, 0, 0);

                return {
                    stratedDay: item.startDate.toISOString(),
                    endedDay: item.endDate.toISOString(),
                };
            });
        } else {
            this.effectDurationDateRanges = null;
        }

    }

    private _setInterferenceEffectAreaName({obstacleArea}: any) {
        this.interferenceEffectArea = obstacleArea;
    }


    private _setFiles({file}: any) {
        this.files = file;
    }

    private _setFrequencyUnitId({frequencyUnits}: any) {
        this.frequencyUnitId = frequencyUnits;
    }
}