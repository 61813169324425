<style src="./step-appeal-radio.component.scss" lang="scss"></style>

<template>
  <div class="step-appeal-radio">
    <step-description-component :stepDescription="stepDescription" :current="current"/>
    <progressbar-component :step="step" :current="current" :stepDescription="stepDescription"/>
    <section v-show="current === 1">
      <general-information-form-component
          :userLogin="userLogin"
          :stepOneValidation="stepOneValidation"
          :userData="userData"
          :legalPersonCarrier="legalPersonCarrier"
          :organizationLegalFormValue="organizationLegalFormValue"
          :citizenshipValue="citizenshipValue"
          :citizenship="citizenship"
          :organizationLegalForm="organizationLegalForm"/>
    </section>
    <section v-show="current === 2">
      <personal-information-form-component
          @setPersonalInformation="(i) => $emit('setPersonalInformation', i)"
          :req="false"
          :organizationName="organizationName"
          @checkTin="checkTin"
          :userLogin="userLogin"
          :documentSeriesType="documentSeriesType"
          :errorPersonalInformation="errorPersonalInformation"
          :organizationLegalFormValue="organizationLegalFormValue"
          :legalPersonCarrier="legalPersonCarrier"
          :citizenshipValue="citizenshipValue"
          :personalInformation="personalInformation"
      />
    </section>
    <section v-show="current === 3">
      <radio-information-form-component
          :frequencyUnits="frequencyUnits"
          @addDates="addDates"
          @deleteDate="deleteDate"
          :interferenceTechnologyTypes="interferenceTechnologyTypes"
          :interferenceTypes="interferenceTypes"
          :durations="durations"
          :radioInformation="radioInformation"
          :errorRadioInformation="errorRadioInformation"/>
    </section>
  </div>
</template>

<script src="./step-appeal-radio.component.ts"></script>